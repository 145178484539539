import React, { useState } from "react";
import { ReactComponent as WhatsAppIcon } from "./assets/logos_whatsapp-icon.svg";
import { ReactComponent as SnapchatIcon } from "./assets/snapchat.svg";
import { ReactComponent as InstagramIcon } from "./assets/skill-icons_instagram.svg";
import { ReactComponent as TiktokIcon } from "./assets/logos_tiktok-icon.svg";
import { ReactComponent as FacebookIcon } from "./assets/logos_facebook.svg";
import Popup from "./Popup";

const HomePage = () => {
  const [showPopup, setShowPopup] = useState(false);

  const handleOpenPopup = () => setShowPopup(true);
  const handleClosePopup = () => setShowPopup(false);

  const socialMediaLinks = {
    whatsapp: "https://wa.me/218916431818",
    tiktok: "https://www.tiktok.com/@houseofmistic",
    facebook: "https://www.facebook.com/Houseofmistic",
    snapchat: "https://www.snapchat.com/add/houseofmistic",
    instagram: "https://www.instagram.com/houseofmistic",
  };

  return (
    <div>
      <div className="w-full max-w-md mb-6 text-center">
        <h3 className="text-2xl text-custom-pink mb-2">Contact us</h3>
        {/* Replace with actual icons */}
        <div className="flex justify-center mb-4 border-2 rounded-lg py-2 border-slate-950 gap-2 hover:bg-gray-200 cursor-pointer">
          <a
            href={socialMediaLinks.whatsapp}
            target="_blank"
            rel="noopener noreferrer"
          >
            <WhatsAppIcon className="fill-current text-black w-6 h-6" />
          </a>

          <span className="text-xl">Whats App</span>
        </div>

        <h3 className="text-2xl text-custom-pink mb-2">Follow us</h3>

        <div className="flex flex-col justify-center">
          <div className="flex justify-center mb-4 border-2 rounded-lg py-2 border-slate-950 gap-2 hover:bg-gray-200 cursor-pointer">
            <a
              href={socialMediaLinks.snapchat}
              target="_blank"
              rel="noopener noreferrer"
            >
              <SnapchatIcon className="fill-current text-black w-6 h-6" />
            </a>
            <span className="text-xl">Snapchat</span>
          </div>

          <div className="flex justify-center mb-4 border-2 rounded-lg py-2 border-slate-950 gap-2 hover:bg-gray-200 cursor-pointer">
            <InstagramIcon className="fill-current text-black w-6 h-6" />
            <a
              href={socialMediaLinks.instagram}
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="text-xl">Instagram</span>
            </a>
          </div>
          <div className="flex justify-center mb-4 border-2 rounded-lg py-2 border-slate-950 gap-2 hover:bg-gray-200 cursor-pointer">
            <a
              href={socialMediaLinks.tiktok}
              target="_blank"
              rel="noopener noreferrer"
            >
              <TiktokIcon className="fill-current text-black w-6 h-6" />
            </a>
            <span className="text-xl">Tiktok</span>
          </div>

          <div className="flex justify-center mb-4 border-2 rounded-lg py-2 border-slate-950 gap-2 hover:bg-gray-200 cursor-pointer">
            <FacebookIcon className="fill-current text-black w-6 h-6" />
            <a
              href={socialMediaLinks.facebook}
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="text-xl">Facebook</span>
            </a>
          </div>
        </div>
      </div>
      {showPopup && <Popup onClose={handleClosePopup} />}

      <footer className="text-xs text-center text-gray-500">
        Copyright © 2023 House Of Mistic.
      </footer>
    </div>
  );
};

export default HomePage;
